import React from 'react'
import { graphql } from 'gatsby'
import { Container, Box, Text, Heading, Card, Flex, Grid, Link } from 'theme-ui'
import { Link as GLink } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-markdown'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-yaml'
import 'prismjs/components/prism-dart'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-http'
import 'prismjs/components/prism-cypher'
import 'prismjs/components/prism-powershell'
import 'prismjs/components/prism-csharp'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../pages/_theme'

const TableOfContents = ({ items = [] }) => {
  console.log('Table of Contents items:', items);
  if (!items || items.length <= 1) return null;

  return (
    <Box sx={{ mb: 5 }}>
      <Heading as="h4" sx={{ mb: 3, color: 'omegaDark' }}>Table of Contents</Heading>
      <Card variant='paper' sx={{ p: 4 }}>
        <Grid gap={3} columns={[1, null, 2]}>
          {items.map((item, index) => (
            <Link
              key={`item-${index}`}
              as={GLink}
              to={item.url}
              sx={{
                textDecoration: 'none',
                color: 'text',
                ':hover': { color: 'primary' }
              }}
            >
              <Flex sx={{ alignItems: 'center' }}>
                <Text sx={{ 
                  color: 'omega',
                  width: '2rem',
                  mr: 3,
                  mb: 0
                }}>
                  {(index + 1).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })}
                </Text>
                <Text sx={{ 
                  flex: 1,
                  mb: 0,
                  ':hover': { color: 'primary' }
                }}>
                  {item.title}
                </Text>
              </Flex>
            </Link>
          ))}
        </Grid>
      </Card>
    </Box>
  );
};

const BlogPostTemplate = ({ data }) => {
  const { allBlockContent, mdx } = data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  console.log('MDX tableOfContents:', mdx.tableOfContents);

  React.useEffect(() => {
    // Highlight code blocks after component mounts
    if (typeof window !== 'undefined') {
      Prism.highlightAll()
    }
  }, [])

  return (
    <Layout theme={theme}>
      <Seo
        title={`${mdx.frontmatter.title} - Toggly Blog`}
        description={mdx.excerpt}
        keywords={mdx.frontmatter.tags}
        author={mdx.frontmatter.author}
        category={mdx.frontmatter.category}
        thumbnail="https://toggly.io/cover.png"
        siteUrl="https://toggly.io"
        locale="en_US"
      />

      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />

      <Container>
        <Box sx={{ maxWidth: '800px', mx: 'auto', px: [3, null, 0] }}>
          {/* Category */}
          <Box sx={{ mb: 3 }}>
            <Text sx={{
              display: 'inline-block',
              px: 3,
              py: 1,
              bg: '#E6F7EC',
              color: '#1B4332',
              borderRadius: 'full',
              fontSize: 1,
              fontWeight: 'medium'
            }}>
              {mdx.frontmatter.category}
            </Text>
          </Box>

          {/* Title */}
          <Heading as="h1" sx={{ 
            fontSize: [4, 5, 6],
            fontWeight: 'bold',
            lineHeight: 'heading',
            mb: 4,
            color: '#000 !important'
          }}>
            {mdx.frontmatter.title}
          </Heading>

          {/* Author and Date */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            color: 'omegaDark',
            fontSize: 2,
            mb: 5
          }}>
            <Text>{mdx.frontmatter.author}</Text>
            <Text>·</Text>
            <Text>
              {new Date(mdx.frontmatter.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </Text>
            <Text>·</Text>
            <Text>3 min</Text>
          </Box>

          {/* Table of Contents */}
          <TableOfContents items={mdx.tableOfContents?.items} />

          {/* Content */}
          <Box sx={{ 
            fontSize: 2,
            lineHeight: 'relaxed',
            color: 'omegaDark',
            '& h2': {
              fontSize: 4,
              mt: 5,
              mb: 3
            },
            '& h3': {
              fontSize: 3,
              mt: 4,
              mb: 3
            },
            '& p': {
              mb: 3
            },
            '& ul, & ol': {
              mb: 3,
              pl: 4
            },
            '& li': {
              mb: 2
            },
            '& code': {
              bg: 'omegaLighter',
              px: 2,
              py: 1,
              borderRadius: 'default',
              fontSize: 1
            },
            '& pre': {
              bg: 'omegaDarker',
              color: 'white',
              p: 3,
              borderRadius: 'lg',
              mb: 3,
              overflow: 'auto',
              fontSize: '0.9em',
              '& code': {
                bg: 'transparent',
                p: 0,
                color: 'inherit'
              }
            },
            '& img': {
              maxWidth: '100%',
              height: 'auto'
            },
            '& .token.comment, & .token.prolog, & .token.doctype, & .token.cdata': {
              color: '#8292a2'
            },
            '& .token.punctuation': {
              color: '#f8f8f2'
            },
            '& .token.property, & .token.tag, & .token.constant, & .token.symbol, & .token.deleted': {
              color: '#f92672'
            },
            '& .token.boolean, & .token.number': {
              color: '#ae81ff'
            },
            '& .token.selector, & .token.attr-name, & .token.string, & .token.char, & .token.builtin, & .token.inserted': {
              color: '#a6e22e'
            },
            '& .token.operator, & .token.entity, & .token.url, & .language-css .token.string, & .style .token.string': {
              color: '#f8f8f2'
            },
            '& .token.atrule, & .token.attr-value, & .token.keyword': {
              color: '#e6db74'
            },
            '& .token.function, & .token.class-name': {
              color: '#e6db74'
            },
            '& .token.regex, & .token.important, & .token.variable': {
              color: '#fd971f'
            }
          }}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </Box>

          {/* Tags */}
          {mdx.frontmatter.tags && (
            <Box sx={{ mt: 5, pt: 4, borderTop: '1px solid', borderColor: 'omegaLighter' }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {mdx.frontmatter.tags.map((tag, index) => (
                  <span
                    key={index}
                    sx={{
                      bg: 'omegaLighter',
                      color: 'omega',
                      px: 3,
                      py: 1,
                      borderRadius: 'full',
                      fontSize: 1
                    }}
                  >
                    {tag.replace(/^#/, '')}
                  </span>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Container>

      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($id: String!) {
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
    mdx(id: { eq: $id }) {
      excerpt
      body
      tableOfContents(maxDepth: 3)
      frontmatter {
        title
        category
        author
        date
        tags
        slug
      }
    }
  }
`

export default BlogPostTemplate 